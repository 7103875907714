
import { defineComponent } from 'vue';
import router from '@/router';

export default defineComponent({
  setup() {
    const loginClick = () => {
      router.push('/login/verification');
    };
    return { loginClick };
  }
});
